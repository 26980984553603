var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login d-flex justify-center align-center"},[_c('div',{staticClass:"login__form"},[_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"mr-2",attrs:{"src":"/imgs/logo_colors.svg","width":"70","max-width":"70"}}),_c('h1',{staticClass:"text-login-sweet mr-2"},[_vm._v("Sweet")]),_c('h1',{staticClass:"text-login-home"},[_vm._v("Home")])],1)]),_c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"formlogin",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.getIn()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('MTextFieldComp',{staticClass:"mt-2",attrs:{"label":"Usuario","rules":[
                        function (v) { return !_vm.m_rule_emptyField(v) || 'El usuario es requerido'; },
                        function (v) { return !_vm.m_rule_withSpaces(v) || 'El usuario no debe contener espacios'; },
                        function (v) { return (v+'').length<=20 || 'El usuario no debe exeder los 20 dígitos'; }
                    ]},on:{"clickenter":function($event){return _vm.ac_getIn()}},model:{value:(_vm.dataUser.username),callback:function ($$v) {_vm.$set(_vm.dataUser, "username", $$v)},expression:"dataUser.username"}}),_c('MTextFieldComp',{staticClass:"mt-2",attrs:{"label":"Contraseña","icon":_vm.activatePassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.activatePassword ? 'text' : 'password',"rules":[
                        function (v) { return !_vm.m_rule_emptyField(v) || 'La contraseña es requerido'; },
                        function (v) { return !_vm.m_rule_withSpaces(v) || 'La contraseña no debe contener espacios'; },
                        function (v) { return (v+'').length<=20 || 'El contraseña no debe exeder los 20 dígitos'; }
                    ]},on:{"clickappend":function($event){_vm.activatePassword=!_vm.activatePassword},"clickenter":function($event){return _vm.ac_getIn()}},model:{value:(_vm.dataUser.password),callback:function ($$v) {_vm.$set(_vm.dataUser, "password", $$v)},expression:"dataUser.password"}})],1),_c('div',{staticClass:"text-center"},[_c('MButtonActionComp',{attrs:{"text":"INGRESAR","loading":_vm.loadingBtnLogin},on:{"click":function($event){return _vm.ac_getIn()}}})],1)],1)]),_c('OSmsErrorComp',{attrs:{"dialog":_vm.dialogError,"smsError":_vm.smsError,"actionError":_vm.actionError},on:{"click":function($event){_vm.dialogError=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }