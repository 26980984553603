<template>
    <div class="login d-flex justify-center align-center">
        <div class="login__form">
            <div class="d-flex justify-center">
                <div class="d-flex align-center">
                    <v-img
                        src="/imgs/logo_colors.svg"
                        width="70"
                        max-width="70"
                        class="mr-2"
                    ></v-img>
                    <h1 class="text-login-sweet mr-2">Sweet</h1>
                    <h1 class="text-login-home">Home</h1>
                </div>
            </div>
            <div class="pa-3">
                <v-form
                    v-model="valid"
                    v-on:submit.prevent="getIn()"
                    lazy-validation
                    ref="formlogin"
                >
                    <MTextFieldComp
                        v-model="dataUser.username"
                        label="Usuario"
                        class="mt-2"
                        :rules="[
                            v => !m_rule_emptyField(v) || 'El usuario es requerido',
                            v => !m_rule_withSpaces(v) || 'El usuario no debe contener espacios',
                            v => (v+'').length<=20 || 'El usuario no debe exeder los 20 dígitos'
                        ]"
                        @clickenter="ac_getIn()"
                    />
                    <MTextFieldComp
                        v-model="dataUser.password"
                        label="Contraseña"
                        class="mt-2"
                        :icon="activatePassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @clickappend="activatePassword=!activatePassword"
                        :type="activatePassword ? 'text' : 'password'"
                        :rules="[
                            v => !m_rule_emptyField(v) || 'La contraseña es requerido',
                            v => !m_rule_withSpaces(v) || 'La contraseña no debe contener espacios',
                            v => (v+'').length<=20 || 'El contraseña no debe exeder los 20 dígitos'
                        ]"
                        @clickenter="ac_getIn()"
                    />
                </v-form>
                <div class="text-center">
                    <MButtonActionComp
                        text="INGRESAR"
                        :loading="loadingBtnLogin"
                        @click="ac_getIn()"
                    />
                </div>
            </div>
        </div>
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="dialogError=false"
        />
    </div>
</template>
<script>
import { MTextFieldComp, MButtonActionComp } from '../../components/molecules'
import { OSmsErrorComp } from '../../components/organisms'
import { rulesMixin, responseServerMixin } from '../../mixins'
import md5 from 'md5'
import { User } from '../../models'
import { mapMutations } from 'vuex'
export default {
    mixins: [ rulesMixin, responseServerMixin ],
    components: {
        MTextFieldComp,
        MButtonActionComp,
        OSmsErrorComp
    },
    data: () => ({
        dialogError: false,
        smsError: [],
        actionError: false,
        activatePassword: false,
        valid: true,
        loadingBtnLogin: false,
        dataUser: new User()
    }),
    methods: {
        ...mapMutations('headerAxiosStore', ['store_axiosHeader']),
        processError(error) {
            if (this.m_response_autorization(error)) {
                if (this.m_response_disabledUser(error)) {
                    this.smsError = ['Su cuenta fue inhabilitada, porfavor comuniquese con el administrador']
                } else {
                    this.smsError = ['El usuario o la contraseña están incorrectos, porfavor revise sus datos']
                }
                this.actionError = true
            } else {
                this.smsError = ['Revise su conección de internet y refresque la página porfavor']
                this.actionError = false
            }
            this.dialogError = true
        },
        validateForm() {
            var admisiondatos = true
            if (!this.$refs.formlogin.validate()) { admisiondatos = false }
            return admisiondatos
        },
        ac_getIn() {
            if (this.validateForm()) {
                this.loadingBtnLogin = true
                const account = {
                    'username': this.dataUser.username,
                    'password': md5(this.dataUser.password)
                }
                User.authenticate(account).then(response => {
                    localStorage.setItem('sweetHome', JSON.stringify(response))
                    var header = {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + response.api_token,
                    }
                    this.store_axiosHeader(header)
                    this.loadingBtnLogin = false
                    this.$router.replace('/website')
                }).catch(error => {
                    this.loadingBtnLogin = false
                    this.processError(error)
                })
            } else {
                this.smsError = ['Corrija los datos marcados con rojo, porfavor']
                this.actionError = true
                this.dialogError = true
            }
        }
    },
    mounted() {
        if (JSON.parse(localStorage.getItem('sweetHome'))) {
            this.$router.replace({name: 'homeAdmin'})
        }
    }
}
</script>
